<template>
    <div>
        <b-modal class="rounded-lg" size="xl" :title="'Actualizacion '+datos.hora+' HORAS'" v-model="openModalSignosVitales"
            no-close-on-esc no-close-on-backdrop hide-header-close hide-footer centered>
            <div class="mt-2">
                <p class="txt_secondary text-uppercase pt-3"><strong>Registro de signos vitales</strong></p>
                <b-row>
                <b-col cols="12" sm="12" md="6" lg="6">
                    <b-table-simple class="table-sm">
                        <b-tbody>
                            <b-tr>
                                <b-th class="th_style">Frecuencia cardiaca</b-th>
                                <b-td class="border_table">
                                    <b-form-input v-model="datos.frecuencia_cardiaca" autocomplete="off"
                                        class="border-0" size="sm"/>
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="th_style">Frecuencia respiratoria</b-th>
                                <b-td class="border_table">
                                    <b-form-input v-model="datos.frecuencia_respiratoria" autocomplete="off"
                                        class="border-0" size="sm"/>
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="th_style">Temperatura corporal</b-th>
                                <b-td class="border_table">
                                    <b-form-input v-model="datos.temperatura" autocomplete="off"
                                        class="border-0" size="sm"/>
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="th_style">Presión sistólica</b-th>
                                <b-td class="border_table">
                                    <b-form-input v-model="datos.presion_sistólica" autocomplete="off"
                                        class="border-0" size="sm"/>
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="th_style">Presión diastólica</b-th>
                                <b-td class="border_table">
                                    <b-form-input v-model="datos.presion_diastólica" autocomplete="off"
                                        class="border-0" size="sm"/>
                                </b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </b-col>
                <b-col cols="12" sm="12" md="6" lg="6">
                    <b-table-simple class="table-sm">
                        <b-tbody>
                            <b-tr>
                                <b-th class="th_style">Presión media</b-th>
                                <b-td class="border_table">
                                    <b-form-input v-model="datos.presion_media" autocomplete="off"
                                        class="border-0" size="sm"/>
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="th_style">Saturación de oxígeno</b-th>
                                <b-td class="border_table">
                                    <b-form-input v-model="datos.spo2" autocomplete="off"
                                        class="border-0" size="sm"/>
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="th_style">Glucometria</b-th>
                                <b-td class="border_table">
                                    <b-form-input v-model="datos.glucometria" autocomplete="off"
                                        class="border-0" size="sm"/>
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="th_style">UI de insulina / Via</b-th>
                                <b-td class="border_table">
                                    <b-form-input v-model="datos.ui" autocomplete="off"
                                        class="border-0" size="sm"/>
                                </b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </b-col>
                </b-row>
                <p class="txt_secondary text-uppercase pt-3"><strong>Escala de valoración</strong></p>
                <b-row>
                <b-col cols="12" sm="12" md="6" lg="6">
                    <b-table-simple class="table-sm">
                        <b-tbody>
                            <b-tr>
                                <b-th class="th_style">Glasgow</b-th>
                                <b-td class="border_table">
                                    <b-form-input v-model="datos.glasgow" autocomplete="off"
                                        class="border-0" size="sm"/>
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="th_style">Nivel de dolor</b-th>
                                <b-td class="border_table">
                                    <b-form-input v-model="datos.nivel_dolor" autocomplete="off"
                                        class="border-0" size="sm"/>
                                </b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </b-col>
                <b-col cols="12" sm="12" md="6" lg="6">
                    <b-table-simple class="table-sm">
                        <b-tbody>
                            <b-tr>
                                <b-th class="th_style">Riesgo de caidas</b-th>
                                <b-td class="border_table">
                                    <b-form-input v-model="datos.riesgo" autocomplete="off"
                                        class="border-0" size="sm"/>
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="th_style">Riesgo de LPP</b-th>
                                <b-td class="border_table">
                                    <b-form-input v-model="datos.riesgo_lpp" autocomplete="off"
                                        class="border-0" size="sm"/>
                                </b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </b-col>
                </b-row>
                <!-- <b-row v-if="errorModal">
                    <b-col cols="12">
                        <div class="contenedor-de-errores pt-3 pb-1">
                            <ul class="lista-errores px-2">
                                <li v-for="(value, key) in erroresModal" :key="key.value"><i
                                        class="fa fa-warning px-1 icon-errror" />| {{ value[0] }} <br></li>
                            </ul>
                        </div>
                    </b-col>
                </b-row> -->
            </div>
            <hr>
            <div class="d-flex justify-content-center mt-2">
                <b-overlay rounded opacity="0.6" spinner-small spinner-variant="primary">
                    <button class="btn-modal-success px-3 pb-2 pt-1 btn_add_user"
                        @click="add()">GUARDAR</button>
                </b-overlay>
                <button @click="cerrarModal()" class="btn-modal-cancel px-3 pb-2 pt-1 ml-4">Cancelar</button>
            </div>
        </b-modal>
    </div>
</template>

<script>

export default {
    name: 'Registrar',
    props: ['openModalSignosVitales'],

    data: () => ({
        datos: {
            hora:'08',
            frecuencia_cardiaca:'',
            frecuencia_respiratoria:'',
            temperatura:'',
            presion_sistólica:'',
            presion_diastólica:'',
            presion_media:'',
            spo2:'',
            glucometria:'',
            ui:'',
            glasgow:'',
            nivel_dolor:'',
            riesgo:'',
            riesgo_lpp:''
        },
    }),

    computed: {
    },

    methods: {
        senData(value){
            this.datos.hora=value
        },
        add(){
            this.$emit('guardar',this.datos)
        },

        cerrarModal() {
            this.$emit('cancelar')
        }
    },

    mounted() {
    }

}
</script>

<style></style>