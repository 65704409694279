<template>
  <div>
    <p class="txt_secondary text-uppercase pt-3"><strong>CONTROL DE LÍQUIDOS</strong></p>
            <b-table-simple :sticky-header="false" :no-border-collapse="true" responsive class="table-sm mt-3">
                <b-thead>
                    <b-tr>
                        <b-th variant="primary" rowspan="2" colspan="2" :sticky-column="true" class="th_style text-center"><strong>INGRESOS</strong></b-th>
                        <b-th class="th_style text-nowrap text-center" colspan="8">MATUTINO</b-th>
                        <b-th rowspan="25" class="th_style text-nowrap text-center" variant="primary" colspan="1"></b-th>
                        <b-th class="th_style text-nowrap text-center" colspan="7">VESPERTINO</b-th>
                        <b-th rowspan="25" class="th_style text-nowrap text-center" variant="primary" colspan="1"></b-th>
                        <b-th class="th_style text-nowrap text-center" colspan="11">NOCTURNO</b-th>
                        <b-th class="th_style text-nowrap text-center" colspan="2"></b-th>
                    </b-tr>
                    <b-tr>
                        <b-th v-for="field of liquidos" :key="field.index" class="th_style text-nowrap text-center">
                            {{ field.key }}
                        </b-th>
                    </b-tr>
                </b-thead>

                <b-tbody>
                    <b-tr v-for="item of ingresos" :key="item.key">
                        <b-td :sticky-column="true" variant="primary" colspan="2"
                            class="text-nowrap border_table th_style text-center">
                            {{ item['key'] }}
                        </b-td>
                        <b-td class="border_table text-center">
                            {{ item['08'] }}
                        </b-td>
                        <b-td class="border_table text-center">
                            {{ item['09'] }}
                        </b-td>
                        <b-td class="border_table text-center">
                            {{ item['10'] }}
                        </b-td>
                        <b-td class="border_table text-center">
                            {{ item['11'] }}
                        </b-td>
                        <b-td class="border_table text-center">
                            {{ item['12'] }}
                        </b-td>
                        <b-td class="border_table text-center">
                            {{ item['13'] }}
                        </b-td>
                        <b-td class="border_table text-center">
                            {{ item['14'] }}
                        </b-td>
                        <b-td class="border_table text-center">
                        </b-td>
                        <b-td class="table-limit border_table text-center">
                            
                        </b-td>
                        <b-td class="border_table text-center">
                            {{ item['15'] }}
                        </b-td>
                        <b-td class="border_table text-center">
                            {{ item['16'] }}
                        </b-td>
                        <b-td class="border_table text-center">
                            {{ item['17'] }}
                        </b-td>
                        <b-td class="border_table text-center">
                            {{ item['18'] }}
                        </b-td>
                        <b-td class="border_table text-center">
                            {{ item['19'] }}
                        </b-td>
                        <b-td class="border_table text-center">
                            {{ item['20'] }}
                        </b-td>
                        <b-td class="border_table text-center">
                        </b-td>
                        <b-td class="table-limit border_table text-center">
                            
                        </b-td>
                        <b-td class="border_table text-center">
                            {{ item['21'] }}
                        </b-td>
                        <b-td class="border_table text-center">
                            {{ item['22'] }}
                        </b-td>
                        <b-td class="border_table text-center">
                            {{ item['23'] }}
                        </b-td>
                        <b-td class="border_table text-center">
                            {{ item['24'] }}
                        </b-td>
                        <b-td class="border_table text-center">
                            {{ item['01'] }}
                        </b-td>
                        <b-td class="border_table text-center">
                            {{ item['02'] }}
                        </b-td>
                        <b-td class="border_table text-center">
                            {{ item['03'] }}
                        </b-td>
                        <b-td class="border_table text-center">
                            {{ item['04'] }}
                        </b-td>
                        <b-td class="border_table text-center">
                            {{ item['05'] }}
                        </b-td>
                        <b-td class="border_table text-center">
                            {{ item['06'] }}
                        </b-td>
                        <b-td class="border_table text-center">
                            {{ item['07'] }}
                        </b-td>
                        <b-td class="border_table text-center">
                        </b-td>
                        <b-td class="border_table text-center">
                        </b-td>
                    </b-tr>
                </b-tbody>
                <b-thead>
                    <b-tr>
                        <b-th variant="primary" colspan="10" class="th_style text-center" ></b-th>
                        <b-th rowspan="25" class="th_style text-nowrap text-center" variant="primary" colspan="1"></b-th>
                        <b-th variant="primary" colspan="7" class="th_style text-center" ></b-th>
                        <b-th rowspan="25" class="th_style text-nowrap text-center" variant="primary" colspan="1"></b-th>
                        <b-th variant="primary" colspan="13" class="th_style text-center" ></b-th>
                    </b-tr>
                    <b-tr>
                        <b-th variant="primary" rowspan="2" colspan="2" :sticky-column="true" class="th_style text-center"><strong>EGRESOS</strong></b-th>
                    </b-tr>
                    <b-tr>
                        <b-th v-for="field of liquidos" :key="field.key" class="th_style text-nowrap text-center">
                            {{ field.key }}
                        </b-th>
                    </b-tr>
                </b-thead>
                <b-tbody>
                    <b-tr v-for="item of egresos" :key="item.key">
                        <b-td :sticky-column="true" variant="primary" colspan="2"
                            class="text-nowrap border_table th_style text-center">
                            {{ item['key'] }}
                        </b-td>
                        <b-td  class="border_table text-center">
                            {{ item['1'] }}
                        </b-td>
                        <b-td class="border_table text-center">
                            {{ item['2'] }}
                        </b-td>
                        <b-td class="border_table text-center">
                            {{ item['3'] }}
                        </b-td>
                        <b-td class="border_table text-center">
                            {{ item['4'] }}
                        </b-td>
                        <b-td class="border_table text-center">
                            {{ item['5'] }}
                        </b-td>
                        <b-td class="border_table text-center">
                            {{ item['6'] }}
                        </b-td>
                        <b-td class="border_table text-center">
                            {{ item['7'] }}
                        </b-td>
                        <b-td class="border_table text-center">
                        </b-td>
                        <b-td class="table-limit border_table text-center">
                            
                        </b-td>
                        <b-td class="border_table text-center">
                            {{ item['8'] }}
                        </b-td>
                        <b-td class="border_table text-center">
                            {{ item['9'] }}
                        </b-td>
                        <b-td class="border_table text-center">
                            {{ item['10'] }}
                        </b-td>
                        <b-td class="border_table text-center">
                            {{ item['11'] }}
                        </b-td>
                        <b-td class="border_table text-center">
                            {{ item['12'] }}
                        </b-td>
                        <b-td class="border_table text-center">
                            {{ item['13'] }}
                        </b-td>
                        <b-td class="border_table text-center">
                        </b-td>
                        <b-td class="table-limit border_table text-center">
                            
                        </b-td>
                        <b-td class="border_table text-center">
                            {{ item['14'] }}
                        </b-td>
                        <b-td class="border_table text-center">
                            {{ item['15'] }}
                        </b-td>
                        <b-td class="border_table text-center">
                            {{ item['16'] }}
                        </b-td>
                        <b-td class="border_table text-center">
                            {{ item['17'] }}
                        </b-td>
                        <b-td class="border_table text-center">
                            {{ item['18'] }}
                        </b-td>
                        <b-td class="border_table text-center">
                            {{ item['19'] }}
                        </b-td>
                        <b-td class="border_table text-center">
                            {{ item['20'] }}
                        </b-td>
                        <b-td class="border_table text-center">
                            {{ item['21'] }}
                        </b-td>
                        <b-td class="border_table text-center">
                            {{ item['22'] }}
                        </b-td>
                        <b-td class="border_table text-center">
                            {{ item['23'] }}
                        </b-td>
                        <b-td class="border_table text-center">
                            {{ item['24'] }}
                        </b-td>
                        <b-td class="border_table text-center">
                        </b-td>
                        <b-td class="border_table text-center">
                        </b-td>

                    </b-tr>
                </b-tbody>
                <b-tbody>
                    <b-tr>
                        <b-td variant="primary" colspan="2" :sticky-column="true" class="th_style text-center" >
                            BH POR TURNO
                        </b-td>
                        <b-td class="border_table text-center" colspan="8">
                            ML
                        </b-td>
                        <b-td class="table-limit border_table text-center">
                            
                        </b-td>
                        <b-td class="border_table text-center" colspan="7">
                            ML
                        </b-td>
                        <b-td class="table-limit border_table text-center">
                            
                        </b-td>
                        <b-td class="border_table text-center" colspan="13">
                            ML
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-td variant="primary" colspan="2" :sticky-column="true" class="th_style text-center">
                            VU POR TURNO
                        </b-td>
                        <b-td class="border_table text-center" colspan="8">
                            ML/KG/HR
                        </b-td>
                        <b-td class="table-limit border_table text-center">
                            
                        </b-td>
                        <b-td class="border_table text-center" colspan="7">
                            ML/KG/HR
                        </b-td>
                        <b-td class="table-limit border_table text-center">
                            
                        </b-td>
                        <b-td class="border_table text-center" colspan="13">
                            ML/KG/HR
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-td variant="primary" colspan="2" class="th_style text-center">
                            BH DE 24 HRS:
                        </b-td>
                        <b-td class="border_table text-center" colspan="13">
                            ML
                        </b-td>
                        <b-td variant="primary" colspan="4" :sticky-column="true" class="th_style text-center">
                            BH DE 24 HRS:
                        </b-td>
                        <b-td class="border_table text-center" colspan="13">
                            ML/KG/HR
                        </b-td>
                    </b-tr>

                </b-tbody>
            </b-table-simple>
  </div>
</template>

<script>
export default {
    name:'controlLiquidos',
    data:()=>({
        liquidos: [
            // { key: 'Registro de signos vitales', stickyColumn: true, isRowHeader: true, variant: 'primary' },
            { key: '08', isRowHeader: true, variant: 'primary' },
            { key: '09', isRowHeader: true, variant: 'primary' },
            { key: '10', isRowHeader: true, variant: 'primary' },
            { key: '11', isRowHeader: true, variant: 'primary' },
            { key: '12', isRowHeader: true, variant: 'primary' },
            { key: '13', isRowHeader: true, variant: 'primary' },
            { key: '14', isRowHeader: true, variant: 'primary' },
            { key: 'TOTAL TM', isRowHeader: true, variant: 'primary' },
            { key: '15', isRowHeader: true, variant: 'primary' },
            { key: '16', isRowHeader: true, variant: 'primary' },
            { key: '17', isRowHeader: true, variant: 'primary' },
            { key: '18', isRowHeader: true, variant: 'primary' },
            { key: '19', isRowHeader: true, variant: 'primary' },
            { key: '20', isRowHeader: true, variant: 'primary' },
            { key: 'TOTAL TV', isRowHeader: true, variant: 'primary' },
            { key: '21', isRowHeader: true, variant: 'primary' },
            { key: '22', isRowHeader: true, variant: 'primary' },
            { key: '23', isRowHeader: true, variant: 'primary' },
            { key: '24', isRowHeader: true, variant: 'primary' },
            { key: '01', isRowHeader: true, variant: 'primary' },
            { key: '02', isRowHeader: true, variant: 'primary' },
            { key: '03', isRowHeader: true, variant: 'primary' },
            { key: '04', isRowHeader: true, variant: 'primary' },
            { key: '05', isRowHeader: true, variant: 'primary' },
            { key: '06', isRowHeader: true, variant: 'primary' },
            { key: '07', isRowHeader: true, variant: 'primary' },
            { key: 'TOTAL TN', isRowHeader: true, variant: 'primary' },
            { key: 'TOTAL 24h', isRowHeader: true, variant: 'primary' },

        ],
        ingresos: [
            { 'key': 'SOLUCIÓN BASE',         '01': '', '02': '', '03': '', '04': '', '05': '', '06': '', '07': '', '08': '', '09': '', '10': '', '11': '', '12': '', '13': '', '14': '', '15': '', '16': '', '17': '', '18': '', '19': '', '20': '', '21': '', '22': '', '23': '', '24': '' },
            { 'key': 'MEDICACIÓN IV',  '01': '', '02': '', '03': '', '04': '', '05': '', '06': '', '07': '', '08': '', '09': '', '10': '', '11': '', '12': '', '13': '', '14': '', '15': '', '16': '', '17': '', '18': '', '19': '', '20': '', '21': '', '22': '', '23': '', '24': '' },
            { 'key': 'MEDICACION VO','01': '', '02': '', '03': '', '04': '', '05': '', '06': '', '07': '', '08': '', '09': '', '10': '', '11': '', '12': '', '13': '', '14': '', '15': '', '16': '', '17': '', '18': '', '19': '', '20': '', '21': '', '22': '', '23': '', '24': '' },
            { 'key': 'HEMODERIBADOS',   '01': '', '02': '', '03': '', '04': '', '05': '', '06': '', '07': '', '08': '', '09': '', '10': '', '11': '', '12': '', '13': '', '14': '', '15': '', '16': '', '17': '', '18': '', '19': '', '20': '', '21': '', '22': '', '23': '', '24': '' },
            { 'key': 'DIETA',   '01': '', '02': '', '03': '', '04': '', '05': '', '06': '', '07': '', '08': '', '09': '', '10': '', '11': '', '12': '', '13': '', '14': '', '15': '', '16': '', '17': '', '18': '', '19': '', '20': '', '21': '', '22': '', '23': '', '24': '' },
            { 'key': 'TOTAL',   '01': '', '02': '', '03': '', '04': '', '05': '', '06': '', '07': '', '08': '', '09': '', '10': '', '11': '', '12': '', '13': '', '14': '', '15': '', '16': '', '17': '', '18': '', '19': '', '20': '', '21': '', '22': '', '23': '', '24': '' }

        ],
        egresos: [
            { 'key': 'URESIS',         '01': '', '02': '', '03': '', '04': '', '05': '', '06': '', '07': '', '08': '', '09': '', '10': '', '11': '', '12': '', '13': '', '14': '', '15': '', '16': '', '17': '', '18': '', '19': '', '20': '', '21': '', '22': '', '23': '', '24': '' },
            { 'key': 'EVACUACIÓN',  '01': '', '02': '', '03': '', '04': '', '05': '', '06': '', '07': '', '08': '', '09': '', '10': '', '11': '', '12': '', '13': '', '14': '', '15': '', '16': '', '17': '', '18': '', '19': '', '20': '', '21': '', '22': '', '23': '', '24': '' },
            { 'key': 'EMESIS','01': '', '02': '', '03': '', '04': '', '05': '', '06': '', '07': '', '08': '', '09': '', '10': '', '11': '', '12': '', '13': '', '14': '', '15': '', '16': '', '17': '', '18': '', '19': '', '20': '', '21': '', '22': '', '23': '', '24': '' },
            { 'key': 'DRENAJE',   '01': '', '02': '', '03': '', '04': '', '05': '', '06': '', '07': '', '08': '', '09': '', '10': '', '11': '', '12': '', '13': '', '14': '', '15': '', '16': '', '17': '', '18': '', '19': '', '20': '', '21': '', '22': '', '23': '', '24': '' },
            { 'key': 'HEMORRAGIAS',   '01': '', '02': '', '03': '', '04': '', '05': '', '06': '', '07': '', '08': '', '09': '', '10': '', '11': '', '12': '', '13': '', '14': '', '15': '', '16': '', '17': '', '18': '', '19': '', '20': '', '21': '', '22': '', '23': '', '24': '' },
            { 'key': 'PÉRD. INSENSIBLES',   '01': '', '02': '', '03': '', '04': '', '05': '', '06': '', '07': '', '08': '', '09': '', '10': '', '11': '', '12': '', '13': '', '14': '', '15': '', '16': '', '17': '', '18': '', '19': '', '20': '', '21': '', '22': '', '23': '', '24': '' },
            { 'key': 'TOTAL',   '01': '', '02': '', '03': '', '04': '', '05': '', '06': '', '07': '', '08': '', '09': '', '10': '', '11': '', '12': '', '13': '', '14': '', '15': '', '16': '', '17': '', '18': '', '19': '', '20': '', '21': '', '22': '', '23': '', '24': '' },
        ],
    })
}
</script>

<style>

</style>