<template>
    <div>
        <b-row  class="justify-content-between mt-0">
            <b-col cols="8">
                <p class="txt_secondary text-uppercase pt-3"><strong>Información de general</strong></p>
            </b-col>
        </b-row>
        <b-table-simple id="table_signos" :sticky-header="false" :no-border-collapse="true" responsive class="table-sm mt-0">
            <b-thead>
                <b-tr>
                    <b-th variant="primary" rowspan="2" colspan="2" :sticky-column="true"
                        class="th_style text-center"><strong>Registro de signos vitales</strong></b-th>
                    <b-th class="th_style text-nowrap text-center" colspan="7">MATUTINO</b-th>
                    <b-th rowspan="25" class="th_style text-nowrap text-center" variant="primary" colspan="1"></b-th>
                    <b-th class="th_style text-nowrap text-center" colspan="6">VESPERTINO</b-th>
                    <b-th rowspan="25" class="th_style text-nowrap text-center" variant="primary" colspan="1"></b-th>
                    <b-th class="th_style text-nowrap text-center" colspan="11">NOCTURNO</b-th>
                </b-tr>
                <b-tr>
                    <b-th v-b-popover.hover.top="'Editar'" v-for="field of fields" :key="field.key" class="th_style text-nowrap text-center table_select">
                        {{ field.key }} <i class="fa fa-edit"></i>
                    </b-th>
                </b-tr>
            </b-thead>
            <b-tbody>
                <b-tr v-for="item of items" :key="item.key">
                    <b-td :sticky-column="true" variant="primary" colspan="2"
                        class="text-nowrap border_table th_style text-center key">
                        {{ item['key'] }}
                    </b-td>
                   <b-td @click="addSignos('08')" class="border_table text-center table_select">
                        <small class="col-08">{{ item['08'] }}</small>
                    </b-td>
                   <b-td @click="addSignos('09')"  class="border_table text-center table_select">
                        <small class="col-09">{{ item['09'] }}</small>
                    </b-td>
                   <b-td @click="addSignos('10')" class="border_table text-center table_select">
                        <small class="col-10">{{ item['10'] }}</small>
                    </b-td>
                   <b-td @click="addSignos('11')" class="border_table text-center table_select">
                        <small class="col-11">{{ item['11'] }}</small>
                    </b-td>
                   <b-td @click="addSignos('12')" class="border_table text-center table_select">
                        <small class="col-12">{{ item['12'] }}</small>
                    </b-td>
                   <b-td @click="addSignos('13')" class="border_table text-center table_select">
                        <small class="col-13">{{ item['13'] }}</small>
                    </b-td>
                   <b-td @click="addSignos('14')" class="border_table text-center table_select">
                        <small class="col-14">{{ item['14'] }}</small>
                    </b-td>
                    <b-td class="table-limit border_table text-center">

                    </b-td>
                   <b-td @click="addSignos('15')" class="border_table text-center table_select">
                        <small class="col-15">{{ item['15'] }}</small>
                    </b-td>
                   <b-td @click="addSignos('16')" class="border_table text-center table_select">
                        <small class="col-16">{{ item['16'] }}</small>
                    </b-td>
                   <b-td @click="addSignos('17')" class="border_table text-center table_select">
                        <small class="col-17">{{ item['17'] }}</small>
                    </b-td>
                   <b-td @click="addSignos('18')" class="border_table text-center table_select">
                        <small class="col-18">{{ item['18'] }}</small>
                    </b-td>
                   <b-td @click="addSignos('19')" class="border_table text-center table_select">
                        <small class="col-19">{{ item['19'] }}</small>
                    </b-td>
                   <b-td @click="addSignos('20')" class="border_table text-center table_select">
                        <small class="col-20">{{ item['20'] }}</small>
                    </b-td>
                    <b-td class="table-limit border_table text-center">

                    </b-td>
                   <b-td @click="addSignos('21')" class="border_table text-center table_select">
                        <small class="col-21">{{ item['21'] }}</small>
                    </b-td>
                   <b-td @click="addSignos('22')" class="border_table text-center table_select">
                        <small class="col-22">{{ item['22'] }}</small>
                    </b-td>
                   <b-td @click="addSignos('23')" class="border_table text-center table_select">
                        <small class="col-23">{{ item['23'] }}</small>
                    </b-td>
                   <b-td @click="addSignos('24')" class="border_table text-center table_select">
                        <small class="col-24">{{ item['24'] }}</small>
                    </b-td>
                   <b-td @click="addSignos('01')" class="border_table text-center table_select">
                        <small class="col-01">{{ item['01'] }}</small>
                    </b-td>
                   <b-td @click="addSignos('02')" class="border_table text-center table_select">
                        <small class="col-02">{{ item['02'] }}</small>
                    </b-td>
                   <b-td @click="addSignos('03')" class="border_table text-center table_select">
                        <small class="col-03">{{ item['03'] }}</small>
                    </b-td>
                   <b-td @click="addSignos('04')" class="border_table text-center table_select">
                        <small class="col-04">{{ item['04'] }}</small>
                    </b-td>
                   <b-td @click="addSignos('05')" class="border_table text-center table_select">
                        <small class="col-05">{{ item['05'] }}</small>
                    </b-td>
                   <b-td @click="addSignos('06')" class="border_table text-center table_select">
                        <small class="col-06">{{ item['06'] }}</small>
                    </b-td>
                   <b-td @click="addSignos('07')" class="border_table text-center table_select">
                        <small class="col-07">{{ item['07'] }}</small>
                    </b-td>
                </b-tr>
            </b-tbody>
            <b-thead>
                <b-tr>
                    <b-th variant="primary" colspan="9" class="th_style text-center"></b-th>
                    <b-th rowspan="25" class="th_style text-nowrap text-center" variant="primary" colspan="1"></b-th>
                    <b-th variant="primary" colspan="6" class="th_style text-center"></b-th>
                    <b-th rowspan="25" class="th_style text-nowrap text-center" variant="primary" colspan="1"></b-th>
                    <b-th variant="primary" colspan="11" class="th_style text-center"></b-th>
                </b-tr>

                <b-tr>
                    <b-th variant="primary" rowspan="2" colspan="2" :sticky-column="true"
                        class="th_style text-center"><strong>Escala de valoración</strong></b-th>
                </b-tr>
                <b-tr>
                    <b-th v-for="field of fields" :key="field.key" class="th_style text-nowrap text-center">
                        {{ field.key }}
                    </b-th>
                </b-tr>
            </b-thead>
            <b-tbody>
                <b-tr v-for="item of valoracion" :key="item.key">
                    <b-td :sticky-column="true" variant="primary" colspan="2"
                        class="text-nowrap border_table th_style text-center key">
                        <small class="col-08">{{ item['key'] }}</small>
                    </b-td>
                    <b-td @click="addSignos('08')" class="border_table text-center table_select">
                        <small class="col-08">{{ item['08'] }}</small>
                    </b-td>
                   <b-td @click="addSignos('09')"  class="border_table text-center table_select">
                        <small class="col-09">{{ item['09'] }}</small>
                    </b-td>
                   <b-td @click="addSignos('10')" class="border_table text-center table_select">
                        <small class="col-10">{{ item['10'] }}</small>
                    </b-td>
                   <b-td @click="addSignos('11')" class="border_table text-center table_select">
                        <small class="col-11">{{ item['11'] }}</small>
                    </b-td>
                   <b-td @click="addSignos('12')" class="border_table text-center table_select">
                        <small class="col-12">{{ item['12'] }}</small>
                    </b-td>
                   <b-td @click="addSignos('13')" class="border_table text-center table_select">
                        <small class="col-13">{{ item['13'] }}</small>
                    </b-td>
                   <b-td @click="addSignos('14')" class="border_table text-center table_select">
                        <small class="col-14">{{ item['14'] }}</small>
                    </b-td>
                    <b-td class="table-limit border_table text-center">

                    </b-td>
                   <b-td @click="addSignos('15')" class="border_table text-center table_select">
                        <small class="col-15">{{ item['15'] }}</small>
                    </b-td>
                   <b-td @click="addSignos('16')" class="border_table text-center table_select">
                        <small class="col-16">{{ item['16'] }}</small>
                    </b-td>
                   <b-td @click="addSignos('17')" class="border_table text-center table_select">
                        <small class="col-17">{{ item['17'] }}</small>
                    </b-td>
                   <b-td @click="addSignos('18')" class="border_table text-center table_select">
                        <small class="col-18">{{ item['18'] }}</small>
                    </b-td>
                   <b-td @click="addSignos('19')" class="border_table text-center table_select">
                        <small class="col-19">{{ item['19'] }}</small>
                    </b-td>
                   <b-td @click="addSignos('20')" class="border_table text-center table_select">
                        <small class="col-20">{{ item['20'] }}</small>
                    </b-td>
                    <b-td class="table-limit border_table text-center">

                    </b-td>
                   <b-td @click="addSignos('21')" class="border_table text-center table_select">
                        <small class="col-21">{{ item['21'] }}</small>
                    </b-td>
                   <b-td @click="addSignos('22')" class="border_table text-center table_select">
                        <small class="col-22">{{ item['22'] }}</small>
                    </b-td>
                   <b-td @click="addSignos('23')" class="border_table text-center table_select">
                        <small class="col-23">{{ item['23'] }}</small>
                    </b-td>
                   <b-td @click="addSignos('24')" class="border_table text-center table_select">
                        <small class="col-24">{{ item['24'] }}</small>
                    </b-td>
                   <b-td @click="addSignos('01')" class="border_table text-center table_select">
                        <small class="col-01">{{ item['01'] }}</small>
                    </b-td>
                   <b-td @click="addSignos('02')" class="border_table text-center table_select">
                        <small class="col-02">{{ item['02'] }}</small>
                    </b-td>
                   <b-td @click="addSignos('03')" class="border_table text-center table_select">
                        <small class="col-03">{{ item['03'] }}</small>
                    </b-td>
                   <b-td @click="addSignos('04')" class="border_table text-center table_select">
                        <small class="col-04">{{ item['04'] }}</small>
                    </b-td>
                   <b-td @click="addSignos('05')" class="border_table text-center table_select">
                        <small class="col-05">{{ item['05'] }}</small>
                    </b-td>
                   <b-td @click="addSignos('06')" class="border_table text-center table_select">
                        <small class="col-06">{{ item['06'] }}</small>
                    </b-td>
                   <b-td @click="addSignos('07')" class="border_table text-center table_select">
                        <small class="col-07">{{ item['07'] }}</small>
                    </b-td>
                </b-tr>
            </b-tbody>
        </b-table-simple>
        <SignosVitalesVue ref="signos" :openModalSignosVitales="addSignosVitales" @cancelar="cerrarModal()" @guardar="guardar($event)" />
    </div>
</template>
<script>
import SignosVitalesVue from '../Modals/SignosVitales.vue'
export default {
    name: 'signosVitales',
    components: { SignosVitalesVue },
    data: () => ({
        addSignosVitales: false,
        fields: [
            // { key: 'Registro de signos vitales', stickyColumn: true, isRowHeader: true, variant: 'primary' },
            { key: '08', isRowHeader: true, variant: 'primary' },
            { key: '09', isRowHeader: true, variant: 'primary' },
            { key: '10', isRowHeader: true, variant: 'primary' },
            { key: '11', isRowHeader: true, variant: 'primary' },
            { key: '12', isRowHeader: true, variant: 'primary' },
            { key: '13', isRowHeader: true, variant: 'primary' },
            { key: '14', isRowHeader: true, variant: 'primary' },
            { key: '15', isRowHeader: true, variant: 'primary' },
            { key: '16', isRowHeader: true, variant: 'primary' },
            { key: '17', isRowHeader: true, variant: 'primary' },
            { key: '18', isRowHeader: true, variant: 'primary' },
            { key: '19', isRowHeader: true, variant: 'primary' },
            { key: '20', isRowHeader: true, variant: 'primary' },
            { key: '21', isRowHeader: true, variant: 'primary' },
            { key: '22', isRowHeader: true, variant: 'primary' },
            { key: '23', isRowHeader: true, variant: 'primary' },
            { key: '24', isRowHeader: true, variant: 'primary' },
            { key: '01', isRowHeader: true, variant: 'primary' },
            { key: '02', isRowHeader: true, variant: 'primary' },
            { key: '03', isRowHeader: true, variant: 'primary' },
            { key: '04', isRowHeader: true, variant: 'primary' },
            { key: '05', isRowHeader: true, variant: 'primary' },
            { key: '06', isRowHeader: true, variant: 'primary' },
            { key: '07', isRowHeader: true, variant: 'primary' },
        ],
        items: [
            { 'key': 'Frecuencia cardiaca' },
            { 'key': 'Frecuencia respiratoria' },
            { 'key': 'Temperatura corporal' },
            { 'key': 'Presión sistólica' },
            { 'key': 'Presión diastólica' },
            { 'key': 'Presión media' },
            { 'key': 'Saturación de oxígeno' },
            { 'key': 'Glucometria' },
            { 'key': 'UI de insulina / Via'},
        ],

        // datos2:{
        //     hora:'08',
        //     Frecuencia_cardiaca:'01',
        //     Frecuencia_respiratoria:'01',
        //     Temperatura_corporal:'01',
        //     Presión_sistólica:'01',
        //     Presión_diastólica:'01',
        //     Presión_media:'01',
        //     Saturación_de_oxígeno:'01',
        //     Glucometria:'01'
        // },

        // datos:{
        //     '08':{Frecuencia_cardiaca:'01', Frecuencia_respiratoria:'01', Temperatura_corporal:'01',...},
        //     '09':{Frecuencia_cardiaca:'01', Frecuencia_respiratoria:'01', Temperatura_corporal:'01',...},
        //     '10':{Frecuencia_cardiaca:'01', Frecuencia_respiratoria:'01', Temperatura_corporal:'01',...},
        //     .
        //     .
        //     .

        // },
        hora:'08',
        valoracion: [
            { 'key': 'GLASGOW' },
            { 'key': 'NIVEL DE DOLOR' },
            { 'key': 'RIESGO DE CAÍDAS' },
            { 'key': 'RIESGO DE LPP' }
        ],
    }),
    methods: {
        guardar(datos){
            console.log(datos);
            this.items[0]={...this.items[0], [datos.hora]:datos.frecuencia_cardiaca}
            this.items[1]={...this.items[1], [datos.hora]:datos.frecuencia_respiratoria}
            this.items[2]={...this.items[2], [datos.hora]:datos.temperatura}
            this.items[3]={...this.items[3], [datos.hora]:datos.presion_sistólica}
            this.items[4]={...this.items[4], [datos.hora]:datos.presion_diastólica}
            this.items[5]={...this.items[5], [datos.hora]:datos.presion_media}
            this.items[6]={...this.items[6], [datos.hora]:datos.spo2}
            this.items[7]={...this.items[7], [datos.hora]:datos.glucometria}
            this.items[8]={...this.items[8], [datos.hora]:datos.ui}

            this.valoracion[0]={...this.valoracion[0],[datos.hora]:datos.glasgow}
            this.valoracion[1]={...this.valoracion[1],[datos.hora]:datos.nivel_dolor}
            this.valoracion[2]={...this.valoracion[2],[datos.hora]:datos.riesgo}
            this.valoracion[3]={...this.valoracion[3],[datos.hora]:datos.riesgo_lpp}
            

            this.addSignosVitales = false
        },
        addSignos(value) {
            console.log(value);
            this.addSignosVitales = true
            this.$refs.signos.senData(value)
        },

        cerrarModal() {
            this.addSignosVitales = false
        },
    }
}
</script>
<style>
.btn_redondo {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 20px;
    text-align: center;
    font-size: 0.8rem;
    line-height: 1.42857;
}
.table_select {
    cursor: pointer;
}
#table_signos{
  overflow: hidden;
}
#table_signos td, #table_signos th {
    position: relative;
}
#table_signos th {
  background-color:#fff;
}

.table_select:hover::after { 
    background-color: #ffa;
    content: '';  
    height: 10000px;    
    left: 0;
    position: absolute;  
    top: -5000px;
    width: 100%;
    z-index: -1;        
}
</style>