<template>
    <b-container>
        <b-container class="py-0">
            <signos-vitales/>
            <b-row>
                <b-col cols="12">
                    <b-table-simple class="table-sm mt-3 table-responsive" responsive>
                        <b-thead>
                            <b-th colspan="12" class="th_style text-center"><strong>SOLUCIONES INTRAVENOSAS</strong></b-th>
                        </b-thead>
                        <b-thead>
                            <b-tr>
                                <b-th colspan="9" class="th_style text-center">PREESCRIPCIÓN</b-th>
                                <b-th class="th_style text-center">INICIO</b-th>
                                <b-th class="th_style text-center">TÉRMINO</b-th>
                                <b-th class="th_style text-center">VELOCIDAD</b-th>
                            </b-tr>
                        </b-thead>
                        <b-tbody>  
                            <b-tr>
                                <b-td colspan="9" class="border_table text-center">
                                    <span class="size-span">123</span>
                                </b-td>
                                <b-td class="border_table text-center">
                                    <span class="size-span">123</span>
                                </b-td>
                                <b-td class="border_table text-center">
                                    <span class="size-span">123</span>
                                </b-td>
                                <b-td class="border_table text-center">
                                    <span class="size-span">123</span>
                                </b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </b-col>
            </b-row>

            <b-row>
                <b-col cols="12">
                    <b-table-simple class="table-sm mt-3 table-responsive" responsive="">
                        <b-thead>
                            <b-th colspan="12" class="th_style text-center"><strong>FARMACOTERAPIA</strong></b-th>
                        </b-thead>
                        <b-thead>
                            <b-tr>
                                <b-th class="th_style text-center">D.I</b-th>
                                <b-th class="th_style text-center">PREESCRIPCIÓN</b-th>
                                <b-th class="th_style text-center">DOSIS</b-th>
                                <b-th class="th_style text-center">VÍA</b-th>
                                <b-th class="th_style text-center">FRECUENCIA</b-th>
                                <b-th class="th_style text-center">T.I.</b-th>
                                <b-th class="th_style text-center">MATUTINO</b-th>
                                <b-th class="th_style text-center">VESPERTINO</b-th>
                                <b-th class="th_style text-center">NOCTURNO</b-th>
                            </b-tr>
                        </b-thead>
                        <b-tbody>  
                            <b-tr>
                                <b-td class="border_table text-center">
                                    <span class="size-span">123</span>
                                </b-td>
                                <b-td class="border_table text-center">
                                    <span class="size-span">123</span>
                                </b-td>
                                <b-td class="border_table text-center">
                                    <span class="size-span">123</span>
                                </b-td>
                                <b-td class="border_table text-center">
                                    <span class="size-span">123</span>
                                </b-td><b-td class="border_table text-center">
                                    <span class="size-span">123</span>
                                </b-td>
                                <b-td class="border_table text-center">
                                    <span class="size-span">123</span>
                                </b-td>
                                <b-td class="border_table text-center">
                                    <span class="size-span">123</span>
                                </b-td>
                                <b-td class="border_table text-center">
                                    <span class="size-span">123</span>
                                </b-td>
                                <b-td class="border_table text-center">
                                    <span class="size-span">123</span>
                                </b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </b-col>
            </b-row>

            <b-row>
                <b-col cols="12">
                    <b-table-simple class="table-sm mt-3 table-responsive" responsive="">
                        <b-thead>
                            <b-th colspan="12" class="th_style text-center"><strong>REGISTRO DE PROCEDIMIENTOS INVASIVOS</strong></b-th>
                        </b-thead>
                        <b-thead>
                            <b-tr>
                                <b-th class="th_style text-center">DISPOSITIVO</b-th>
                                <b-th class="th_style text-center">CALIBRE</b-th>
                                <b-th class="th_style text-center">No. DE PUNCIONES</b-th>
                                <b-th class="th_style text-center">SITIO DE INSERCCIÓN</b-th>
                                <b-th class="th_style text-center">FECHA DE INSTALACIÓN</b-th>
                                <b-th class="th_style text-center">FECHA DE CAMBIO</b-th>
                                <b-th class="th_style text-center">DÍAS DE INSTALADO</b-th>
                                <b-th class="th_style text-center">FECHA DE CURACIÓN</b-th>
                                <b-th class="th_style text-center">RESPONSABLE DE INSTALACIÓN</b-th>
                                <b-th class="th_style text-center">MOTIVO DE RETIRO Y OBSERVACIONES</b-th>
                            </b-tr>
                        </b-thead>
                        <b-tbody>  
                            <b-tr>
                                <b-td class="border_table text-center">
                                    <span class="size-span">123</span>
                                </b-td>
                                <b-td class="border_table text-center">
                                    <span class="size-span">123</span>
                                </b-td>
                                <b-td class="border_table text-center">
                                    <span class="size-span">123</span>
                                </b-td>
                                <b-td class="border_table text-center">
                                    <span class="size-span">123</span>
                                </b-td><b-td class="border_table text-center">
                                    <span class="size-span">123</span>
                                </b-td>
                                <b-td class="border_table text-center">
                                    <span class="size-span">123</span>
                                </b-td>
                                <b-td class="border_table text-center">
                                    <span class="size-span">123</span>
                                </b-td>
                                <b-td class="border_table text-center">
                                    <span class="size-span">123</span>
                                </b-td>
                                <b-td class="border_table text-center">
                                    <span class="size-span">123</span>
                                </b-td>
                                <b-td class="border_table text-center">
                                    <span class="size-span">123</span>
                                </b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </b-col>
            </b-row>

            <b-row>
                <b-col cols="12">
                    <b-table-simple class="table-sm mt-3 table-responsive" responsive="">
                        <b-thead>
                            <b-th colspan="12" class="th_style text-center"><strong>DIETA PREESCRITA</strong></b-th>
                        </b-thead>
                        <b-thead>
                            <b-tr>
                                <b-th colspan="4" class="th_style text-center">TM</b-th>
                                <b-th colspan="4" class="th_style text-center">TV</b-th>
                                <b-th colspan="4" class="th_style text-center">TN</b-th>
                            </b-tr>
                            <b-tr>
                                <b-th colspan="2" class="th_style text-center">INGESTA</b-th>
                                <b-th colspan="2" class="th_style text-center">INGESTA</b-th>
                                <b-th colspan="2" class="th_style text-center">INGESTA</b-th>
                                <b-th colspan="2" class="th_style text-center">INGESTA</b-th>
                                <b-th colspan="2" class="th_style text-center">INGESTA</b-th>
                                <b-th colspan="2" class="th_style text-center">INGESTA</b-th>
                            </b-tr>
                        </b-thead>
                        <b-tbody>  
                            <b-tr>
                                <b-td class="border_table text-center">
                                    <span class="size-span">HR: </span>
                                </b-td>
                                <b-td class="border_table text-center">
                                    <span class="size-span">%</span>
                                </b-td>
                                <b-td class="border_table text-center">
                                    <span class="size-span">HR: </span>
                                </b-td>
                                <b-td class="border_table text-center">
                                    <span class="size-span">%</span>
                                </b-td>
                                <b-td class="border_table text-center">
                                    <span class="size-span">HR: </span>
                                </b-td>
                                <b-td class="border_table text-center">
                                    <span class="size-span">%</span>
                                </b-td>
                                <b-td class="border_table text-center">
                                    <span class="size-span">HR: </span>
                                </b-td>
                                <b-td class="border_table text-center">
                                    <span class="size-span">%</span>
                                </b-td>
                                <b-td class="border_table text-center">
                                    <span class="size-span">HR: </span>
                                </b-td>
                                <b-td class="border_table text-center">
                                    <span class="size-span">%</span>
                                </b-td>
                                <b-td class="border_table text-center">
                                    <span class="size-span">HR: </span>
                                </b-td>
                                <b-td class="border_table text-center">
                                    <span class="size-span">%</span>
                                </b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </b-col>
            </b-row>

            <p class="txt_secondary text-uppercase pt-3"><strong>INDICACIONES MÉDICAS</strong></p>
            <b-row>
                <b-col cols="12">
                    <b-form-textarea autocomplete="off" class="txtArea_border" size="sm" no-resize  rows="5" max-rows="5" />
                </b-col>
            </b-row>

            <ControlLiquidos/>

            <b-table-simple responsive>
                <b-thead>
                    <b-tr>
                        <b-th variant="primary" colspan="3" class="th_style text-center"><strong>VALORACIÓN DE ENFERMERÍA</strong></b-th>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style text-nowrap text-center" >MATUTINO</b-th>
                        <b-th class="th_style text-nowrap text-center" >VESPERTINO</b-th>
                        <b-th class="th_style text-nowrap text-center" >NOCTURNO</b-th>
                    </b-tr>
                </b-thead>
                <b-tbody>
                    <b-tr>
                        <b-td class="border_table text-center">
                            <b-form-textarea autocomplete="off" class="border-0" size="sm" no-resize  rows="5" max-rows="5" />
                        </b-td>
                        <b-td class="border_table text-center">
                            <b-form-textarea autocomplete="off" class="border-0" size="sm" no-resize  rows="5" max-rows="5" />
                        </b-td>
                        <b-td class="border_table text-center">
                            <b-form-textarea autocomplete="off" class="border-0" size="sm" no-resize  rows="5" max-rows="5" />
                        </b-td>
                    </b-tr>
                </b-tbody>
                <b-thead>
                    <b-tr>
                        <b-th class="th_style text-nowrap text-center" >PENDIENTES</b-th>
                        <b-th class="th_style text-nowrap text-center" >PENDIENTES</b-th>
                        <b-th class="th_style text-nowrap text-center" >PENDIENTES</b-th>
                    </b-tr>
                </b-thead>
                <b-tbody>
                    <b-tr>
                        <b-td class="border_table text-center">
                            <b-form-textarea autocomplete="off" class="border-0" size="sm" no-resize  rows="5" max-rows="5" />
                        </b-td>
                        <b-td class="border_table text-center">
                            <b-form-textarea autocomplete="off" class="border-0" size="sm" no-resize  rows="5" max-rows="5" />
                        </b-td>
                        <b-td class="border_table text-center">
                            <b-form-textarea autocomplete="off" class="border-0" size="sm" no-resize  rows="5" max-rows="5" />
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
        </b-container>
    </b-container>
</template>

<script>
import SignosVitales from '../../../components/Enfermeria/Clinico/SignosVitales.vue';
import ControlLiquidos from '../../../components/Enfermeria/Clinico/ControlLiquidos.vue';
export default {
    name: 'registroClinico',
    components:{ SignosVitales, ControlLiquidos  },
    data: () => ({
        busy: false,
        paciente:{},
    }),
    methods: {
        soloNumeros(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
    }
}
</script>
<style>
.border-r {
    border-right: 2px solid #B4CCD7 !important;
}
.border-l {
    border-left: 2px solid #B4CCD7 !important;
}

</style>